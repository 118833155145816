import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { GetGiftCardGraphicsDetailsDto } from "../../../models/api/giftCardsApiTypes"
import { Modal } from "react-bootstrap"
import { GiftCardPreviewInfo } from "../../../models/api/ordersApiTypes"
import AllowedAmount from "../AllowedAmount"
import GiftCardGraphicsCarousel from "../GiftCardGraphicsCarousel"
import OrderSkeleton from "../OrderSkeleton"
import cn from "classnames"
import "./style.css"

type OrderFormType = {
  recipientName: string
  recipientEmail: string
  graphicId: string
  graphicUrl: string
  price: string
  customPrice: string | undefined
  message: string
}

interface EditGiftCardProps {
  show: boolean
  onClose: () => void
  giftCardGraphics: GetGiftCardGraphicsDetailsDto[] | undefined
  handleEditGiftCard: (index: number, EditdGiftCard: GiftCardPreviewInfo) => void
  giftCardToEdit: GiftCardPreviewInfo
  index: number
}

export default function EditGiftCard({
  show,
  onClose,
  giftCardGraphics,
  handleEditGiftCard,
  giftCardToEdit,
  index,
}: EditGiftCardProps) {
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm<OrderFormType>()
  const [isCustomAmount, setIsCustomAmount] = useState(false)
  const selectedGraphic = watch("graphicId")
  const selectedAmount = watch("price")
  const messageValue = watch("message")
  const messageMaxChars = 250
  const minimumAmount = 20
  const maximumAmount = 200
  const priceMultiplier = 100

  useEffect(() => {
    if (show) {
      setValue("recipientName", giftCardToEdit.recipientName)
      setValue("recipientEmail", giftCardToEdit.recipientEmail)
      setValue("graphicId", giftCardToEdit.graphicId)
      setValue("graphicUrl", giftCardToEdit.graphicUrl)
      setValue("price", giftCardToEdit.isCustomPrice ? "Altro" : (giftCardToEdit.price / priceMultiplier).toString())
      setValue(
        "customPrice",
        giftCardToEdit.isCustomPrice ? (giftCardToEdit.price / priceMultiplier).toString() : undefined
      )
      setValue("message", giftCardToEdit.message)
      setIsCustomAmount(giftCardToEdit.isCustomPrice)
      clearErrors()
    }
  }, [show, setValue, clearErrors, giftCardToEdit])

  const handleSelectAmount = (value: string) => {
    setValue("price", value)
    setIsCustomAmount(value === "Altro")
    if (value !== "Altro") {
      setValue("customPrice", undefined)
    }
    clearErrors("price")
  }

  const handleSelectGraphic = (graphicId: string, graphicUrl: string) => {
    setValue("graphicId", graphicId)
    setValue("graphicUrl", graphicUrl)
    clearErrors("graphicId")
    clearErrors("graphicUrl")
  }

  const onSubmit = async (data: OrderFormType) => {
    const itemPrice: number = (data.customPrice ? parseInt(data.customPrice) : parseInt(data.price)) * priceMultiplier

    const giftCard: GiftCardPreviewInfo = {
      recipientName: data.recipientName,
      recipientEmail: data.recipientEmail,
      graphicId: data.graphicId,
      graphicUrl: data.graphicUrl,
      price: itemPrice,
      isCustomPrice: data.customPrice !== undefined,
      message: data.message,
      deliveryDate: null,
      validFrom: null,
    }
    handleEditGiftCard(index, giftCard)
    onClose()
  }

  const onError = () => {
    toast.error("Compila correttamente tutti i campi e riprova.")
  }

  return (
    <Modal show={show} onHide={onClose} centered dialogClassName="modal-container">
      <Modal.Header closeButton>
        <Modal.Title>Scegli la tua GiftCard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {giftCardGraphics ? (
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <h4 className="mt-4">Scegli un design per la tua Gift Card</h4>
            <div className="row">
              <div className="col-12 mb-4">
                <GiftCardGraphicsCarousel
                  graphics={giftCardGraphics}
                  selectedGraphic={selectedGraphic}
                  onSelectGraphic={handleSelectGraphic}
                />
                <input
                  type="hidden"
                  className={cn("form-control", { "is-invalid": errors.graphicId })}
                  {...register("graphicId", { required: "Seleziona una grafica" })}
                />
                {errors.graphicId && <div className="invalid-feedback">{errors.graphicId.message}</div>}
              </div>
            </div>
            <h4 className="mt-1">Scegli un importo</h4>
            <div className="row">
              <div className="col-12 mb-4">
                <AllowedAmount selectedAmount={selectedAmount} onSelectAmount={handleSelectAmount} />
                <input
                  type="hidden"
                  className={cn("form-control", { "is-invalid": errors.price })}
                  {...register("price", { required: "Seleziona un importo o inseriscilo premendo 'Altro'" })}
                />
                {errors.price && <div className="invalid-feedback">{errors.price.message}</div>}
              </div>
            </div>

            {isCustomAmount ? (
              <div className="row">
                <div className="col-12 mb-4">
                  <label htmlFor="ownerName" className="form-label">
                    Scrivi l&apos;importo
                  </label>
                  <input
                    placeholder="Importo desiderato"
                    type="number"
                    className={cn("form-control", { "is-invalid": errors.customPrice })}
                    {...register("customPrice", {
                      required: "Inserisci l'importo",
                      min: { value: minimumAmount, message: `Il valore minimo è ${minimumAmount} euro` },
                      max: { value: maximumAmount, message: `Il valore minimo è ${maximumAmount} euro` },
                    })}
                  />
                  {errors.customPrice && <div className="invalid-feedback">{errors.customPrice.message}</div>}
                </div>
              </div>
            ) : null}

            <h4 className="mt-1">A chi la mandiamo?</h4>
            <p>
              Attenzione! L&apos;indirizzo email che inserisci qui sotto riceverà un PDF con la grafica selezionata,
              l&apos;importo, ed un QR Code da scannerizzare in negozio. Potrai anche stampare il PDF e portarlo
              fisicamente in negozio.
            </p>
            <div className="row">
              <div className="col-12 mb-4">
                <label htmlFor="recipientName" className="form-label">
                  Nome Ricevente
                </label>
                <input
                  placeholder="Mario Rossi"
                  type="text"
                  className={cn("form-control", { "is-invalid": errors.recipientName })}
                  {...register("recipientName", { required: "Inserisci il nome del ricevente" })}
                />
                {errors.recipientName && <div className="invalid-feedback">{errors.recipientName.message}</div>}
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-4">
                <label htmlFor="recipientEmail" className="form-label">
                  Email Ricevente
                </label>
                <input
                  placeholder="mario.rossi@buonisulserio.it"
                  type="text"
                  className={cn("form-control", { "is-invalid": errors.recipientEmail })}
                  {...register("recipientEmail", {
                    required: "Inserisci l'indirizzo email del ricevente",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Inserisci un indirizzo valido",
                    },
                  })}
                />
                {errors.recipientEmail && <div className="invalid-feedback">{errors.recipientEmail.message}</div>}
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-4">
                <label htmlFor="message" className="form-label">
                  Messaggio
                </label>
                <textarea
                  maxLength={messageMaxChars}
                  style={{ resize: "none" }}
                  placeholder="Inserisci qua il tuo messaggio..."
                  rows={6}
                  className="form-control"
                  {...register("message", { maxLength: messageMaxChars })}
                />
                <div className="mt-2" style={{ fontSize: "14px", opacity: "80%" }}>
                  Caratteri rimanenti: {messageMaxChars - messageValue?.length}
                </div>
              </div>
            </div>
            <div className="mt-2 mb-4 text-center">
              <input type="submit" className="btn btn-violet" value="Aggiorna" />
            </div>
          </form>
        ) : (
          <OrderSkeleton />
        )}
      </Modal.Body>
    </Modal>
  )
}
