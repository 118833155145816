import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import {
  CreateOrderItemsDto,
  CreateOrderPaymentResponseDto,
  GiftCardPreviewInfo,
  GiftCardsItems,
} from "../models/api/ordersApiTypes"
import { createOrderItemsApi, createOrderPaymentApi } from "../api/ordersApi"
import { getGiftCardGraphicsApi } from "../api/giftCardsApi"
import { GetGiftCardGraphicsDetailsDto } from "../models/api/giftCardsApiTypes"
import OrderPageHeader from "../components/order/OrderPageHeader"
import GiftCardPreview from "../components/order/GiftCardPreview"
import OrderTermsConditions from "../components/order/OrderTermsConditions"
import AddGiftCard from "../components/order/AddGiftCard"
import EditGiftCard from "../components/order/EditGiftCard"
import cn from "classnames"
/* import Select from "react-select" */

export interface SelectOption {
  value: string
  label: string
}

type OrdersGeneralInfo = {
  customerName: string
  customerEmail: string
  giftCardsNumber: number
  paymentMethod: string
  acceptTerms: boolean
}

const getEmptyGiftCardPreview = (): GiftCardPreviewInfo => ({
  recipientName: "",
  recipientEmail: "",
  graphicId: "",
  graphicUrl: "",
  price: 0,
  isCustomPrice: false,
  message: "",
  deliveryDate: null,
  validFrom: null,
})

const groupGiftCardsByPrice = (giftCards: GiftCardPreviewInfo[]): GiftCardsItems[] => {
  const groupedGiftCards: { [price: number]: GiftCardsItems } = {}

  giftCards.forEach((giftCard) => {
    if (!groupedGiftCards[giftCard.price]) {
      groupedGiftCards[giftCard.price] = {
        price: giftCard.price,
        quantity: 0,
        giftCards: [],
      }
    }
    groupedGiftCards[giftCard.price].quantity += 1
    groupedGiftCards[giftCard.price].giftCards.push({
      graphicId: giftCard.graphicId,
      message: giftCard.message,
      recipientName: giftCard.recipientName,
      recipientEmail: giftCard.recipientEmail,
      deliveryDate: giftCard.deliveryDate,
      validFrom: giftCard.validFrom,
    })
  })

  return Object.values(groupedGiftCards)
}

export default function OrderPage() {
  const {
    register,
    setValue,
    clearErrors,
    /* watch, */
    formState: { errors },
    handleSubmit,
  } = useForm<OrdersGeneralInfo>()
  const [isLoading, setIsLoading] = useState(false)
  const [showAddGiftCard, setShowAddGiftCard] = useState(true)
  const [showEditGiftCard, setShowEditGiftCard] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [giftCards, setGiftCards] = useState<GiftCardPreviewInfo[]>([])
  const [giftCardToEdit, setGiftCardToEdit] = useState<GiftCardPreviewInfo>(getEmptyGiftCardPreview)
  const [giftCardToEditIndex, setGiftCardToEditIndex] = useState<number>(0)
  const [giftCardGraphics, setGiftCardGraphics] = useState<GetGiftCardGraphicsDetailsDto[]>()

  /*   const selectedPaymentMethod = watch("paymentMethod")
  const paymentMethosOptions: SelectOption[] = [
    { value: "0", label: "Carta di credito/debito" },
    { value: "1", label: "Bonifico" },
  ] */

  useEffect(() => {
    getGiftCardGraphicsApi()
      .then((result) => setGiftCardGraphics(result))
      .catch(() => toast.error("Qualcosa è andato storto. Prova a ricaricare la pagina."))
  }, [])

  const handleShowAddGiftCardModal = () => setShowAddGiftCard(true)
  const handleCloseAddGiftCardModal = () => setShowAddGiftCard(false)

  const handleShowEditGiftCardModal = (index: number, giftCardToEdit: GiftCardPreviewInfo) => {
    setGiftCardToEdit(giftCardToEdit)
    setGiftCardToEditIndex(index)
    setShowEditGiftCard(true)
  }
  const handleCloseEditGiftCardModal = () => {
    setGiftCardToEdit(getEmptyGiftCardPreview)
    setGiftCardToEditIndex(0)
    setShowEditGiftCard(false)
  }

  const handleShowTermsModal = () => setShowTerms(true)
  const handleCloseTermsModal = () => setShowTerms(false)

  const handleAddGiftCard = (giftCard: GiftCardPreviewInfo) => {
    setValue("giftCardsNumber", giftCards.length + 1)
    clearErrors("giftCardsNumber")
    setGiftCards((prevGiftCards) => [...prevGiftCards, giftCard])
  }

  const handleEditGiftCard = (index: number, editedGiftCard: GiftCardPreviewInfo) => {
    setGiftCards((prevGiftCards) => prevGiftCards.map((giftCard, i) => (i === index ? editedGiftCard : giftCard)))
  }

  const handleRemoveGiftCard = (index: number) => {
    setValue("giftCardsNumber", giftCards.length - 1)
    setGiftCards((prevGiftCards) => prevGiftCards.filter((_, i) => i !== index))
  }

  /*   const hanldeSelectPaymentMethod = (methodType: string | undefined) => {
    if (methodType === "0") setValue("paymentMethod", "CARD")
    else setValue("paymentMethod", "BANK_TRANSFER")
    clearErrors("paymentMethod")
  } */

  const onSubmit = async (data: OrdersGeneralInfo) => {
    const createOrderItemsDto: CreateOrderItemsDto = {
      customerName: data.customerName,
      customerEmail: data.customerEmail,
      giftCardsItems: groupGiftCardsByPrice(giftCards),
    }

    setIsLoading(true)
    createOrderItemsApi(createOrderItemsDto)
      .then((orderId: string) => {
        createOrderPaymentApi(orderId, {
          successUrl: `${process.env.REACT_APP_BASE_URL}/ordine?order_id=${orderId}`,
          cancelUrl: `${process.env.REACT_APP_BASE_URL}/acquista-gift-card`,
          paymentMethod: "STRIPE",
          paymentType: "CARD" /* selectedPaymentMethod */,
          notes: "",
        })
          .then((response: CreateOrderPaymentResponseDto) => {
            toast.success("Stai per essere reindirizzato alla pagina di pagamento.")
            window.location.href = response.paymentUrl
          })
          .catch(() => {
            toast.error("Qualcosa è andato storto. Riprova!")
          })
          .finally(() => setIsLoading(false))
      })
      .catch(() => {
        toast.error("Qualcosa è andato storto. Riprova!")
        setIsLoading(false)
      })
  }

  const onError = () => {
    toast.error("Compila correttamente tutti i campi e riprova.")
  }

  return (
    <div
      style={{ backgroundColor: "#FFF", maxWidth: "850px" }}
      className="mt-0 mb-0 mt-md-4 mb-md-4 rounded-4 mx-auto p-4"
    >
      <div className="text-end">
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/img/icons/close.svg`} alt="Close" />
        </Link>
      </div>
      <OrderPageHeader />
      <div style={{ maxWidth: "576px" }} className="mx-auto mt-0 mb-0 mt-sm-4 mb-sm-4">
        <>
          <AddGiftCard
            show={showAddGiftCard}
            onClose={handleCloseAddGiftCardModal}
            giftCardGraphics={giftCardGraphics}
            handleAddGiftCard={handleAddGiftCard}
          />
          <EditGiftCard
            show={showEditGiftCard}
            onClose={handleCloseEditGiftCardModal}
            giftCardGraphics={giftCardGraphics}
            handleEditGiftCard={handleEditGiftCard}
            giftCardToEdit={giftCardToEdit}
            index={giftCardToEditIndex}
          />
          <OrderTermsConditions show={showTerms} onClose={handleCloseTermsModal} />
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <fieldset disabled={isLoading}>
              <h4 className="mb-4">Inserisci e informazioni richieste</h4>
              {/* <h5>Aggiungi e personalizza le GiftCard</h5> */}
              <h5>Aggiungi e personalizza la tua GiftCard</h5>
              <div className="row">
                <div className="col-12 mb-4">
                  {giftCards ? (
                    <>
                      {giftCards.map((giftCrad, index) => (
                        <div key={index} /* className="mb-4" */>
                          <GiftCardPreview
                            giftCard={giftCrad}
                            index={index}
                            handleRemoveGiftCard={handleRemoveGiftCard}
                            handleShowEditGiftCardModal={handleShowEditGiftCardModal}
                          />
                        </div>
                      ))}
                    </>
                  ) : null}

                  {giftCards.length === 0 ? (
                    <div style={{ cursor: "pointer" }} onClick={handleShowAddGiftCardModal}>
                      {/* <u>Aggiungi una GiftCard</u> */}
                      <u>Aggiungi la tua GiftCard GiftCard</u>
                    </div>
                  ) : null}

                  <input
                    type="hidden"
                    className={errors.giftCardsNumber ? "is-invalid" : ""}
                    {...register("giftCardsNumber", {
                      required: "Aggiungi almeno una GiftCard",
                      min: { value: 1, message: `Aggiungi almeno una GiftCard` },
                    })}
                  />
                  {errors.giftCardsNumber && <div className="invalid-feedback">{errors.giftCardsNumber.message}</div>}
                </div>
              </div>
              <h5>Inserisci le informazioni del mittente</h5>
              <div className="row">
                <div className="col-12 mb-4">
                  <label htmlFor="customerName" className="form-label">
                    Nome Mittente
                  </label>
                  <input
                    placeholder="Mario Rossi"
                    type="text"
                    className={cn("form-control", { "is-invalid": errors.customerName })}
                    {...register("customerName", { required: "Inserisci il nome del mittente" })}
                  />
                  {errors.customerName && <div className="invalid-feedback">{errors.customerName.message}</div>}
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-4">
                  <label htmlFor="customerEmail" className="form-label">
                    Email Mittente
                  </label>
                  <input
                    placeholder="mario.rossi@gmail.com"
                    type="text"
                    className={cn("form-control", { "is-invalid": errors.customerEmail })}
                    {...register("customerEmail", {
                      required: "Inserisci l'indirizzo email del mittente",
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Inserisci un indirizzo email valido",
                      },
                    })}
                  />
                  {errors.customerEmail && <div className="invalid-feedback">{errors.customerEmail.message}</div>}
                </div>
              </div>
              {/*               <h5>Scegli il metodo di pagamento che preferisci</h5>
              <div className="row">
                <div className="col-12 mb-5">
                  <label htmlFor="customerEmail" className="form-label">
                    Scegli un metodo di pagamento
                  </label>
                  <Select
                    options={paymentMethosOptions}
                    name="paymentMethod"
                    placeholder="Metodo di pagamento"
                    onChange={(selectedOption) => hanldeSelectPaymentMethod(selectedOption?.value)}
                  />
                  <input
                    type="hidden"
                    className={cn("form-control", { "is-invalid": errors.paymentMethod })}
                    {...register("paymentMethod", { required: "Seleziona un metodo di pagamento" })}
                  />
                  {errors.paymentMethod && <div className="invalid-feedback">{errors.paymentMethod.message}</div>}
                </div>
              </div> */}

              <div className="row">
                <div className="col-12 mb-4">
                  <input
                    style={{ marginRight: "10px" }}
                    type="checkbox"
                    className={errors.acceptTerms ? "is-invalid" : ""}
                    {...register("acceptTerms", { required: "Accetta i Termini e le Condizioni" })}
                  />
                  Accetto i{" "}
                  <u style={{ cursor: "pointer", color: "blue" }} onClick={handleShowTermsModal}>
                    Termini e le Condizioni
                  </u>
                  {errors.acceptTerms && <div className="invalid-feedback">{errors.acceptTerms.message}</div>}
                </div>
              </div>
            </fieldset>

            <div className="d-grid gap-2 mt-2 mb-4 text-center">
              <div>
                {!isLoading ? (
                  <input type="submit" className="btn btn-violet" value="Acquista ora" />
                ) : (
                  <button type="submit" className="btn btn-violet">
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>{" "}
                    Caricamento...
                  </button>
                )}
              </div>
            </div>
          </form>
        </>
      </div>
    </div>
  )
}
