import {
  GetOrderDetailsDto,
  CreateOrderItemsDto,
  CreateOrderPaymentDto,
  CreateOrderPaymentResponseDto,
} from "../models/api/ordersApiTypes"
import { api } from "./clientApi"

export function createOrderItemsApi(payload: CreateOrderItemsDto) {
  return new Promise<string>((resolve, reject) => {
    api
      .post("/Orders/items", payload)
      .then((data) => resolve(data.data))
      .catch((err) => reject(err))
  })
}

export function createOrderPaymentApi(orderId: string | undefined, payload: CreateOrderPaymentDto) {
  return new Promise<CreateOrderPaymentResponseDto>((resolve, reject) => {
    api
      .post(`/Orders/${orderId}/payment`, payload)
      .then((data) => resolve(data.data))
      .catch((err) => reject(err))
  })
}

export function getOrderDetailsApi(orderId: string | undefined) {
  return new Promise<GetOrderDetailsDto>((resolve, reject) => {
    api
      .get(`/Orders/${orderId}`)
      .then((data) => resolve(data.data))
      .catch((err) => reject(err))
  })
}
