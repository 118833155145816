import { GiftCardPreviewInfo } from "../../../models/api/ordersApiTypes"
import useCheckMobileResolution from "../../../hook/useCheckMobileResolution"
import "./style.css"

interface GiftCardPreviewProps {
  giftCard: GiftCardPreviewInfo
  index: number
  handleRemoveGiftCard: (index: number) => void
  handleShowEditGiftCardModal: (index: number, EditdGiftCard: GiftCardPreviewInfo) => void
}

export default function GiftCardPreview({
  giftCard,
  index,
  /* handleRemoveGiftCard, */
  handleShowEditGiftCardModal,
}: GiftCardPreviewProps) {
  const isMobile = useCheckMobileResolution()
  const priceMultiplier = 100

  return (
    <div
      className="gift-card-preview-container"
      style={isMobile ? { flexDirection: "column", alignItems: "center" } : { flexDirection: "row" }}
    >
      <img
        style={isMobile ? { width: "120px", marginBottom: "10px" } : { width: "120px" }}
        src={giftCard.graphicUrl}
        alt="Grafica selezionata"
      />
      <div className="gift-card-preview-info" style={{ marginLeft: "20px" }}>
        <div className="gift-card-preview-info-line">
          Nome destinatario: <i>{giftCard.recipientName}</i>
        </div>
        <div className="gift-card-preview-info-line">
          Email destinatario: <i>{giftCard.recipientEmail}</i>
        </div>
        <div className="gift-card-preview-info-line gift-card-message">
          Messaggio:{" "}
          <i>{giftCard.message.length > 30 ? `${giftCard.message.substring(0, 30)}...` : giftCard.message}</i>
        </div>
        <div style={{ marginTop: "auto", fontWeight: "600" }}>{giftCard.price / priceMultiplier}€</div>
      </div>

      <div style={{ display: "flex", marginBottom: "auto" }}>
        <img
          src={`${process.env.PUBLIC_URL}/img/edit-icon.svg`}
          alt="edit"
          id="edit_logo"
          style={{ width: "22px", /* marginRight: "5px",  */ cursor: "pointer" }}
          onClick={() => handleShowEditGiftCardModal(index, giftCard)}
        />
        {/*         <img
          src={`${process.env.PUBLIC_URL}/img/delete-icon.svg`}
          alt="delete logo"
          id="delete_logo"
          style={{ width: "20px", cursor: "pointer" }}
          onClick={() => handleRemoveGiftCard(index)}
        /> */}
      </div>
    </div>
  )
}
